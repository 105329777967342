import React from "react";
import { HomeOutlined, ShopOutlined, TagOutlined, TwitterOutlined, FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import {NavLink} from "react-router-dom";
import {ROUTE_TO_MAIN_PAGE, ROUTE_TO_SCHEME_LIST} from "../../../App";

import logo from 'asset/logo.png'
import './styles.less';
import {Divider, Space, Typography} from "antd";

export const Menu: React.FC = () => {
  return (
    <div className="menu">
      <NavLink to={ROUTE_TO_MAIN_PAGE}>
        <img className="menu--logo" src={logo} />
      </NavLink>
      <ul className="menu--list">
        <li className="menu--list-item">
          <NavLink className="nav-link" to={ROUTE_TO_SCHEME_LIST}>
            <HomeOutlined /> Схемы
          </NavLink>
        </li>
        <li className="menu--list-item">
          <NavLink className="nav-link" to={ROUTE_TO_SCHEME_LIST}>
            <ShopOutlined /> Магазин
          </NavLink>
        </li>
        <li className="menu--list-item">
          <NavLink className="nav-link" to={ROUTE_TO_SCHEME_LIST}>
            <TagOutlined /> Мулине
          </NavLink>
        </li>
      </ul>
      <Divider style={{ borderColor: "rgba(255, 255, 255, 0.1)"}} />
      <ul className="menu--list">
        <li className="menu--list-item">
          <NavLink className="nav-link nav-link_small" to={ROUTE_TO_SCHEME_LIST}>
            Отзывы
          </NavLink>
        </li>
        <li className="menu--list-item">
          <NavLink className="nav-link nav-link_small" to={ROUTE_TO_SCHEME_LIST}>
            Условия использования
          </NavLink>
        </li>
        <li className="menu--list-item">
          <NavLink className="nav-link nav-link_small" to={ROUTE_TO_SCHEME_LIST}>
            Политика конфиденциальности
          </NavLink>
        </li>
        <li className="menu--list-item">
          <NavLink className="nav-link nav-link_small" to={ROUTE_TO_SCHEME_LIST}>
            Лицензия
          </NavLink>
        </li>
        <li className="menu--list-item">
          <NavLink className="nav-link nav-link_small" to={ROUTE_TO_SCHEME_LIST}>
            FAQ
          </NavLink>
        </li>
      </ul>
      <Divider style={{ borderColor: "rgba(255, 255, 255, 0.1)"}} />
      <div className="menu--socials">
        <Space size="large">
          <Typography.Link className="menu--socials-link">
            <TwitterOutlined />
          </Typography.Link>
          <Typography.Link className="menu--socials-link">
            <FacebookOutlined />
          </Typography.Link>
          <Typography.Link className="menu--socials-link">
            <InstagramOutlined />
          </Typography.Link>
        </Space>
      </div>
    </div>
  );
};
