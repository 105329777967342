import {isNil} from "lodash-es";
import {Point, RectData, SplitColor} from "./types";

export const SECTION_RECT_SIZE = 10;
export const CANVAS_WIDTH = 1200;

export function coordsToPointIndex(x: number, y: number, pointSize: number, schemeWidth: number ): number {
  return Math.floor(x / pointSize) + Math.floor(y / pointSize) * schemeWidth;
}

export function pointsToRectData(
  points: Point[],
  filterColor: string,
  pointSize: number,
  pointsInLine: number,
): RectData[] {
  return points.map((point, index) => {
    return {
      from: {
        x: pointSize * (index % pointsInLine),
        y: pointSize * (index - index % pointsInLine) / pointsInLine,
      },
      to: {
        x: pointSize,
        y: pointSize,
      },
      color: point.color,
      filter: filterColor !== "" && point.color !== filterColor,
      symbol: point.symbol,
      complete: point.complete,
    }
  });
}

export function drawPoints(ctx: CanvasRenderingContext2D, rects: RectData[]) {
  rects.forEach((data) => {
    drawRect(ctx, data);
    //draySymbol(ctx, data);
    drawCompletedRect(ctx, data);
  });
}

function drawRect(ctx: CanvasRenderingContext2D, data: RectData) {
  if (data.filter) {
    ctx.fillStyle = toGreyScale(data.color);
  } else {
    ctx.fillStyle = data.color;
  }

  ctx.fillRect(data.from.x, data.from.y, data.to.x, data.to.y);
}

function drawCompletedRect(ctx: CanvasRenderingContext2D, data: RectData) {
  if (data.complete) {
    ctx.fillStyle = '#000000';
    ctx.fillRect(data.from.x, data.from.y, data.to.x, data.to.y);
  }
}

function draySymbol(ctx: CanvasRenderingContext2D, data: RectData) {
  if (data.filter) {
    ctx.fillStyle = toGreyScale(data.color);
  } else {
    ctx.fillStyle = data.color;
  }

  const fontSize =  Math.round(data.to.x / 2);

  ctx.fillStyle = "white";
  ctx.font = `${fontSize}px Glyph`;

  // TODO: Remove temporary shift values for x and y
  ctx.fillText(String.fromCharCode(parseInt(`0x${data.symbol}`, 16)), data.from.x + 4, data.from.y + 12);
}

function hexToRgb(hex: string): SplitColor | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function toGreyScale(hexColor: string) {
  const rgbColor = hexToRgb(hexColor);

  if (isNil(rgbColor)) {
    return "#ffffff";
  }

  const greyColor = rgbColor.r * 0.3 + rgbColor.g * 0.59 + rgbColor.b * 0.11;

  return `rgb(${greyColor}, ${greyColor}, ${greyColor})`;
}

