import { api } from "src/reusables/api";
import {Pattern, PatternDetails} from "./types";
import {BASE_URL} from "../index";

export const patternsRepository = {
  getPatterns: (): Promise<Pattern[]> => api.get(BASE_URL + `/patterns`),
  getPatternById: (patternId: Pattern['patternId']): Promise<PatternDetails> => api.get(BASE_URL + `/patterns/${patternId}`),
  getPatternDataById: (patternId: Pattern['patternId']): Promise<Uint8Array> => api.sget(BASE_URL + `/patterns/${patternId}/data`),
  getPatternMarksById: (patternId: Pattern['patternId']): Promise<Uint8Array> => api.sget(BASE_URL + `/patterns/${patternId}/marks`),
};
