import React from "react";
import { Filter } from './Filter';

import './styles.less';
import {Point} from "../types";
import {Space} from "antd";

export const PatternFilter: React.FC<Props> = (props) => {
  const { pattern, filterColor, onSelect } = props;
  const map = new Map();

  pattern.map((point) => {
    const filter = map.get(point.color);

    if (filter) {
      return map.set(point.color, [...filter, point]);
    }

    return map.set(point.color, [point]);
  });

  // TODO: Implement through declarative methods
  const renderFilters = () => {
    const filters = [];

    for (let item of map) {
      filters.push(
        <Filter
          key={item[0]}
          color={item[0]}
          count={item[1].length}
          onSelect={onSelect}
          filterColor={filterColor}
        />);
    }

    return filters;
  }

  return (
    <div className="pattern-filter">
      <Space size="large" direction="vertical">
        { renderFilters() }
      </Space>
    </div>
  )
}

type Props = {
  pattern: Point[],
  filterColor: string,
  onSelect: (color: string) => void,
}
