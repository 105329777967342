import React, {ReactNode} from "react";

import './styles.less';

export const Section: React.FC<{ children: ReactNode, color?: string }> = ({ children, color }) => {
  return (
    <div className="section" style={{ background: color ? color : "#dee2e6" }}>
      { children }
    </div>
  );
};
