import React from "react";
import { BlockOutlined, BulbOutlined, HighlightOutlined, BgColorsOutlined } from '@ant-design/icons';
import { Space } from "antd";

import './styles.less';

export const InstrumentalPanel: React.FC = () => {
  return (
    <div className="instrumental-panel">
      <Space size="large" direction="vertical">
        <div className="instrumental-panel--item">
          <BlockOutlined />
        </div>
        <div className="instrumental-panel--item">
          <BulbOutlined />
        </div>
        <div className="instrumental-panel--item">
          <HighlightOutlined />
        </div>
        <div className="instrumental-panel--item">
          <BgColorsOutlined />
        </div>
      </Space>
    </div>
  )
}
