import {useEffect, useState} from "react";
import {Pattern} from "../../externals/Patterns/types";
import {Api} from "../../externals";

export function useBehaviour({ sources }: Props) {
  const [schemeList, setSchemeList] = useState([] as Pattern[]);
  const [filteredList, setFilteredList] = useState([] as Pattern[]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    sources.getPatterns().then((patterns) => {
      setSchemeList(patterns);
      setFilteredList(patterns);
      setLoading(false);
    });
  }, []);

  const onSearch = (search: string) => {
    setFilteredList(() => {
      return schemeList.filter((schemeItem) => {
        return schemeItem.title.toLowerCase().includes(search.toLowerCase());
      });
    });
  }

  return {
    filteredList,
    loading,
    onSearch,
  };
}

type Props = {
  sources: Pick<Api, 'getPatterns'>;
};