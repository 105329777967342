import React from "react";

import './styles.less';

export const Filter: React.FC<FilterProps> = (props) => {
  const { color, count, filterColor, onSelect } = props;

  return (
    <div className="filter" onClick={() => onSelect(color)}>
      <div className="filter-color" style={{background: color,  outlineColor: color === filterColor ? '#ffffff' : 'transparent'}}>
      </div>
      <div className="filter-counter">
        { count }
      </div>
    </div>
  )
}

type FilterProps = {
  color: string,
  count: number,
  filterColor: string,
  onSelect: (color: string) => void,
}