import React from "react";
import {Props} from "./useBehaviour";
import {Progress, Typography} from "antd";
import { BorderOuterOutlined, ClockCircleOutlined, BgColorsOutlined } from '@ant-design/icons';

import './styles.less';
import {BASE_URL} from "../../../externals";
import {Pattern} from "../../../externals/Patterns/types";
import {NavLink} from "react-router-dom";

export const View: React.FC<Props> = ({ schemeItem }) => {
  const ellipsis = {
    rows: 2,
    expandable: false,
    tooltip: false,
  };

  // TODO: Get from patterns api
  const getImageUrl = (patternId: Pattern['patternId']) => {
    return `${BASE_URL}/patterns/${patternId}/image`;
  }

  const getLinkToPattern = (patternId: Pattern['patternId']) => {
    return `/patterns/${patternId}`;
  }

  return (
    <div className="scheme-preview">
      <NavLink  to={getLinkToPattern(schemeItem.patternId)}>
        <div className="content">
          <div className="wrapper">
            <div className="image">
              <img src={schemeItem.patternId} />
            </div>
            <div className="description">
              <div className="title">
                <Typography.Paragraph ellipsis={ellipsis}>
                  { schemeItem.title }
                </Typography.Paragraph>
              </div>
              <div className="meta-data">
                <div className="meta-data-item">
                  <BorderOuterOutlined />
                  { schemeItem.width } x { schemeItem.height }
                </div>
                <div className="meta-data-item">
                  <BgColorsOutlined />
                  { schemeItem.colorCount }
                </div>
                <div className="meta-data-item">
                  <ClockCircleOutlined />
                  { formattedTime(schemeItem.timeSpentMs) }
                </div>
              </div>
            </div>
          </div>
          <div className="progress">
            <Progress
              type="circle"
              percent={schemeItem.progress * 100}
              strokeColor={{
                '0%': '#ea868f',
                '100%': '#dc3545',
              }}
              width={80}
              format={(percent) => <span style={{ color: "#000000" }}>{ percent }%</span>}
            />
          </div>
        </div>
      </NavLink>
    </div>
  )
}

// TODO: Use Dayjs instead this function
function formattedTime(spentTime: number): string {
  const hours = Math.floor(spentTime / (1000 * 60 * 60));
  const minutes = Math.floor((spentTime - (hours * 1000 * 60 * 60)) / (1000 * 60));

  if (minutes < 10) {
    return `${hours}:0${minutes}`;
  }

  return `${hours}:${minutes}`;
}
