import React from "react";
import {Col, Row, Space, Spin, Typography} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {SchemePreview} from "./SchemePreview";
import {SchemesListFilter} from "./SchemesListFilter";
import {Section} from "../../reusables/Section";
import { Pattern } from "src/externals/Patterns/types";

import './styles.less';

export const View: React.FC<{filteredList: Pattern[], loading: boolean, onSearch: (search: string) => void}> = (props) => {
  const renderSchemePreviews = () => {
    return props.filteredList.map((schemeItem) => {
      return (
        <Col key={schemeItem.patternId} span={8}>
          <SchemePreview schemeItem={schemeItem} />
        </Col>
      );
    });
  }

  return (
    <Space size="large" direction="vertical">
      <Section color="transparent">
        <Typography.Title>
          Схемы
        </Typography.Title>
      </Section>
      <Section>
        <SchemesListFilter onSearch={props.onSearch} />
      </Section>
      <Section>
        {
          props.filteredList.length > 0 && (
            <Row gutter={[16, 16]}>
              { renderSchemePreviews() }
            </Row>
          )
        }
        {
          !props.loading && props.filteredList.length === 0 && (
            <div className="patterns-not-found">
              <Typography.Text>
                Ничего не найдено
              </Typography.Text>
            </div>
          )
        }
        {
          props.loading && (
            <div className="patterns-not-found">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 36, color: "#6c757d" }} spin />} />
            </div>
          )
        }
      </Section>
    </Space>
  )
}
