import {LineData, PatternMeta} from "./types";
import {range} from "lodash-es";
import {CANVAS_WIDTH, SECTION_RECT_SIZE} from "./drawPoints";

export function drawGrid(ctx: CanvasRenderingContext2D, patternMeta: PatternMeta) {
  const pointSize = CANVAS_WIDTH / patternMeta.width;
  const canvasHeight = pointSize * patternMeta.height;

  const horizontalLines = range(0, patternMeta.height).map((index) => {
    return {
      from: {x: 0, y: index * pointSize},
      to: {x: CANVAS_WIDTH, y: index * pointSize},
      color: "#ffffff",
      width: 1,
    }
  });

  const verticalLines = range(0, patternMeta.width).map((index) => {
    return {
      from: {x: index * pointSize, y: 0},
      to: {x: index * pointSize, y: canvasHeight},
      color: "#ffffff",
      width: 1,
    }
  });

  const horizontalSectionLines = range(0, Math.floor(patternMeta.height / SECTION_RECT_SIZE) + 1).map((index) => {
    return {
      from: {x: 0, y: index * pointSize * SECTION_RECT_SIZE},
      to: {x: CANVAS_WIDTH, y: index * pointSize * SECTION_RECT_SIZE},
      color: "#ffffff",
      width: 2,
    }
  });

  const verticalSectionLines = range(0, Math.floor(patternMeta.width / SECTION_RECT_SIZE) + 1).map((index) => {
    return {
      from: {x: index * pointSize * SECTION_RECT_SIZE, y: 0},
      to: {x: index * pointSize * SECTION_RECT_SIZE, y: canvasHeight},
      color: "#ffffff",
      width: 2,
    }
  });

  const lastHorizontalSectionLines = {
    from: {x: 0, y: canvasHeight},
    to: {x: CANVAS_WIDTH, y: canvasHeight},
    color: "#ffffff",
    width: 2,
  };

  const lastVerticalSectionLines = {
    from: {x: CANVAS_WIDTH, y: 0},
    to: {x: CANVAS_WIDTH, y: canvasHeight},
    color: "#ffffff",
    width: 2,
  };

  const grid = [
    ...horizontalLines,
    ...verticalLines,
    ...horizontalSectionLines,
    ...verticalSectionLines,
    lastHorizontalSectionLines,
    lastVerticalSectionLines,
  ];

  grid.map((line) => drawLine(ctx, line));
}

function drawLine(ctx: CanvasRenderingContext2D, data: LineData) {
  ctx.strokeStyle = data.color;
  ctx.lineWidth = data.width;

  ctx.beginPath();
  ctx.moveTo(data.from.x, data.from.y);
  ctx.lineTo(data.to.x, data.to.y);
  ctx.stroke();
}