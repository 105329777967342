import React from 'react';
import {Outlet, Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { MainPage } from "./stories/MainPage";
import { SchemesList } from './stories/SchemesList';
import { Pattern } from "./stories/Pattern";
import {Layout} from "./reusables/Layout";

import 'normalize.css';
import './global.css';

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path={ROUTE_TO_MAIN_PAGE} element={<MainPage />} />
          <Route path={ROUTE_TO_SCHEME_LIST} element={<SchemesList />} />
          <Route path={ROUTE_TO_PATTERN} element={<Pattern />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export const ROUTE_TO_MAIN_PAGE = '/';
export const ROUTE_TO_SCHEME_LIST = '/patterns';
export const ROUTE_TO_PATTERN = '/patterns/:patternId';
