import React from "react";
import {Typography} from "antd";
import {Section} from "../../reusables/Section";

export const View: React.FC<{sources: string}> = (props) => {
  return (
    <Section color="transparent">
      <Typography.Title>
        Mane page
      </Typography.Title>
    </Section>
  )
}