import React, {ReactNode} from "react";
import { Menu } from "./Menu";

import './styles.less';

export const View: React.FC<{children: ReactNode}> = ({ children }) => {
  return (
    <div className="layout">
      <Menu />
      <div className="layout--main-container">
        { children }
      </div>
    </div>
  )
}