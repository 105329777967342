import React from 'react';
import ReactDom from 'react-dom';

import { App } from './App';

ReactDom.render(<App />, createRoot());

function createRoot() {
  const root = document.createElement('div');

  root.setAttribute('id', 'root');

  document.body.prepend(root);

  return root;
}
