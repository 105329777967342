import React, { useEffect } from "react";
import { isNil } from "lodash-es";
import { PatternFilter } from "./PatternFilter";
import {
  CANVAS_WIDTH,
  coordsToPointIndex, drawPoints,
  pointsToRectData,
} from "./drawPoints";
import { PatternMeta, Point } from "./types";

import './styles.less';
import {drawGrid} from "./drawGrid";
import {InstrumentalPanel} from "./InstrumentalPanel";

export const View: React.FC<Props> = ({
  pattern,
  filterColor,
  onPointClick,
  patternMeta,
  onFilteredSelect,
}) => {
  const canvasRef = React.createRef<HTMLCanvasElement>();

  const onClick = (e: React.MouseEvent) => {
    onPointClick(
      coordsToPointIndex(
        e.nativeEvent.offsetX,
        e.nativeEvent.offsetY,
        CANVAS_WIDTH / patternMeta.width,
        patternMeta.width,
      )
    );
  };

  useEffect(() => {
    if (isNil(canvasRef.current)) {
      return;
    }

    const ctx = canvasRef.current.getContext('2d');

    if (isNil(ctx)) {
      return;
    }

    const pointSize = CANVAS_WIDTH / patternMeta.width;

    drawPoints(ctx, pointsToRectData(
      pattern,
      filterColor,
      pointSize,
      patternMeta.width,
    ))

    drawGrid(ctx, patternMeta)
  }, [canvasRef]);

  return (
    <div className="pattern">
      <InstrumentalPanel />
      <PatternFilter pattern={pattern} onSelect={onFilteredSelect} filterColor={filterColor} />

      {
        pattern.length > 0 &&
          <div className="pattern--canvas-container">
            <canvas
              ref={canvasRef}
              width={CANVAS_WIDTH}
              height={ CANVAS_WIDTH / patternMeta.width * patternMeta.height }
              style={{ background: "#ffffff" }}
              onClick={onClick}
            >
            </canvas>
          </div>
      }
    </div>
  )
}

type Props = {
  pattern: Point[],
  patternMeta: PatternMeta,
  filterColor: string,
  onPointClick: (index: number) => void,
  onFilteredSelect: (color: string) => void,
}
