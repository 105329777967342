import {useEffect, useState} from "react";
import {Api} from "../../externals";
import {Pattern, PatternDetails, PointMeta} from "../../externals/Patterns/types";
import {useParams} from "react-router-dom";
import {PatternMeta, Point} from "./types";

export function useBehaviour({ sources }: Props) {
  const [pattern, setPattern] = useState([] as Point[]);
  const [patternMeta, setPatternMeta] = useState({ width: 0, height: 0 } as PatternMeta);
  const [filterColor, setFilterColor] = useState("");
  const { patternId } = useParams();

  useEffect(() => {
    Promise.all([
      sources.getPatternById(patternId as Pattern['patternId']),
      sources.getPatternDataById(patternId as Pattern['patternId']),
      sources.getPatternMarksById(patternId as Pattern['patternId'])
    ])
      .then(([patternDetails, paletteIndexes, marksIndexes]) => {
        setPatternMeta({
          width: patternDetails.width,
          height: patternDetails.height,
        });

        const points = paletteIndexes.reduce((accum, paletteIndex, index) => {
          const isSelected = marksIndexes[index] === 1;
          const pointMeta = patternDetails.palette[paletteIndex];

          return [...accum, createPoint(patternDetails, pointMeta, isSelected, index)];
        }, [] as Point[]);

        setPattern(points);
      });
  }, [patternId]);

  const onFilteredSelect = (color: string) => {
    setFilterColor((prevColor) => prevColor === color ? "" : color);
  }

  const onPointClick = (index: number) => setPattern((points) => {
    return [
      ...points.slice(0, index),
      {
        ...points[index],
        complete: !points[index].complete,
      },
      ...points.slice(index + 1),
    ]
  });

  return {
    pattern,
    filterColor,
    onFilteredSelect,
    onPointClick,
    patternMeta,
  };
}

function createPoint(
  patternDetails: PatternDetails,
  pointMeta: PointMeta,
  isSelected: boolean,
  index: number,
): Point {
  return {
    id: index,
    color: pointMeta.color,
    symbol: pointMeta.symbol,
    complete: isSelected,
  }
}

type Props = {
  sources: Pick<Api, 'getPatternById' | 'getPatternDataById' | 'getPatternMarksById'>;
};