import {Pattern} from "../../../externals/Patterns/types";

export function useBehaviour({ schemeItem }: Props) {
  return {
    schemeItem,
  };
}

export type Props = {
  schemeItem: Pattern,
}
