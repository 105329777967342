export const api = {
  get: <TResponse>(url: string) => {
    return fetch(url).then(parse) as Promise<TResponse>;
  },
  sget: <TResponse>(url: string) => {
    return fetch(url).then(parseStream) as Promise<TResponse>;
  },
};

async function parse(response: Response): Promise<unknown> {
  if (!response.ok) {
    return Promise.reject();
  }

  return response.json()
}

async function parseStream(response: Response): Promise<unknown> {
  if (!response.ok) {
    return Promise.reject();
  }

  const arrayBuffer = await response.arrayBuffer()

  return new Uint8Array(arrayBuffer);
}
